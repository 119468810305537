function App() {
    return (
        <div className="container container-m">
            <div className="row justify-content-md-center">
                <div className="col-md-8">
                    <div className="card">
                        <img src="images/belsmeta-cloud-promo-1.png" className="card-img-top" alt="..." />
                        <div className="card-body">
                            <h5 className="card-title">Belsmeta.Cloud</h5>
                            <p className="card-text">Новый программный комплекс по расчёту смет и процентовок Belsmeta.Cloud.</p>
                            <ul>
                                <li>минимальные затраты на приобретение</li>
                                <li>гибкие тарифные планы</li>
                                <li>автоматическое бесплатное обновление программы-клиента</li>
                                <li>автоматическое обновление баз данных</li>
                            </ul>
                            <a href="https://www.belsmeta.by/" className="btn btn-primary">
                                Подробнее
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default App
